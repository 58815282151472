var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "sharingOptions",
      attrs: { color: "#ffffff", width: "100%" }
    },
    [
      _c(
        "v-tabs",
        {
          staticClass: "settings-tabs",
          class: { "pt-5": _vm.$vuetify.breakpoint.mdAndUp },
          attrs: {
            "center-active": !_vm.$vuetify.breakpoint.mdAndUp,
            centered: !_vm.$vuetify.breakpoint.mdAndUp,
            vertical: _vm.$vuetify.breakpoint.mdAndUp,
            "show-arrows": ""
          },
          on: {
            change: function($event) {
              return _vm.tabChange($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "mt-3",
              class: {
                column: _vm.$vuetify.breakpoint.mdAndUp,
                "d-flex": !_vm.$vuetify.breakpoint.mdAndUp
              },
              staticStyle: { "max-height": "calc(100vh - 96px)" },
              style: {
                "overflow-y": _vm.$vuetify.breakpoint.mdAndUp
                  ? "auto"
                  : "hidden"
              }
            },
            [
              _c(
                "v-tab",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSubPageOpen,
                      expression: "!isSubPageOpen"
                    }
                  ],
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    { staticStyle: { width: "38px" }, attrs: { left: "" } },
                    [_vm._v("fal fa-person")]
                  ),
                  _vm.userMode == "T"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("sharingPermissionsLabel")))
                      ])
                    : _vm._e(),
                  _vm.userMode == "A"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("adminPermissionsLabel")))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm.userMode == "T"
                ? _c(
                    "v-tab",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isSubPageOpen,
                          expression: "!isSubPageOpen"
                        }
                      ],
                      staticClass: "justify-start"
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("fal fa-screen-users")
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("sharingAllTeacherLabel")))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isSubPageOpen && _vm.userMode == "T"
                ? _vm._l(_vm.localSharingProfiles, function(item, i) {
                    return _c(
                      "v-tab",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isSubPageOpen,
                            expression: "!isSubPageOpen"
                          }
                        ],
                        key: i,
                        staticClass: "justify-start"
                      },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("fal fa-school")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("viewLabel")) +
                              " - " +
                              _vm._s(item.name)
                          )
                        ])
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.userMode == "T"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isSubPageOpen,
                          expression: "!isSubPageOpen"
                        }
                      ],
                      staticClass: "buttonTab justify-start pl-4 pt-3 pb-3",
                      attrs: { tabindex: "0", role: "tab" },
                      on: {
                        click: _vm.openNewViewProfile,
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.openNewViewProfile.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "primary", small: "" } }, [
                        _vm._v("fal fa-user-plus")
                      ]),
                      _c("span", { staticClass: "ml-2 primary--text" }, [
                        _vm._v(_vm._s(_vm.$t("addViewProfileLabel")))
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c("pb-confirm", { ref: "deleteViewProfileConfirm" }),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "", width: "100%", "max-width": "800px" } },
                  [
                    _vm.userMode == "A"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-switch", {
                                  staticClass: "pa-0 pl-2 mt-2",
                                  attrs: {
                                    inset: "",
                                    label: _vm.$t(
                                      "teachersCreateLessonBanksLabel"
                                    ),
                                    "hide-details": "auto"
                                  },
                                  on: { change: _vm.updateAdminSharingOptions },
                                  model: {
                                    value:
                                      _vm.adminSharingOptions
                                        .allowCreateLessonBanks,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.adminSharingOptions,
                                        "allowCreateLessonBanks",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "adminSharingOptions.allowCreateLessonBanks"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.userMode == "A"
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-switch", {
                                  staticClass: "pa-0 pl-2 ma-0",
                                  attrs: {
                                    inset: "",
                                    label: _vm.$t(
                                      "teachersViewLessonBanksLabel"
                                    ),
                                    "hide-details": "auto"
                                  },
                                  on: { change: _vm.updateAdminSharingOptions },
                                  model: {
                                    value:
                                      _vm.adminSharingOptions
                                        .allowViewLessonBanks,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.adminSharingOptions,
                                        "allowViewLessonBanks",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "adminSharingOptions.allowViewLessonBanks"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-card-title",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.addOrEditNewPermission({})
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("fal fa-plus")
                            ]),
                            _c("span", { staticClass: "ml-1" }, [
                              _vm._v(_vm._s(_vm.$t("addLabel")))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: { click: _vm.toggleEditMode }
                          },
                          [
                            _vm.editMode
                              ? _c(
                                  "div",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fal fa-pen-slash")
                                    ]),
                                    _c("span", { staticClass: "ml-1" }, [
                                      _vm._v(_vm._s(_vm.$t("doneLabel")))
                                    ])
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fal fa-pen-to-square")
                                    ]),
                                    _c("span", { staticClass: "ml-1" }, [
                                      _vm._v(_vm._s(_vm.$t("editLabel")))
                                    ])
                                  ],
                                  1
                                )
                          ]
                        ),
                        _vm.hasSelectedRows
                          ? _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "error" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletePermission()
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-trash-alt")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("deleteLabel")))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          staticClass: "mx-2",
                          attrs: {
                            clearable: "",
                            dense: "",
                            outlined: "",
                            "single-line": "",
                            "hide-details": "",
                            "prepend-inner-icon": "fal fa-search",
                            label: _vm.$t("searchLabel")
                          },
                          model: {
                            value: _vm.localSearch,
                            callback: function($$v) {
                              _vm.localSearch = $$v
                            },
                            expression: "localSearch"
                          }
                        })
                      ],
                      1
                    ),
                    _c("v-data-table", {
                      attrs: {
                        headers: _vm.localHeaders,
                        items: _vm.lessonPermissions,
                        search: _vm.localSearch,
                        "show-select": _vm.editMode,
                        height: _vm.$vuetify.breakpoint.mdAndUp
                          ? "calc(100vh - 240px)"
                          : "calc(100vh - 280px)",
                        loading: _vm.isTableLoading,
                        "fixed-header": "",
                        "item-key": "id",
                        "hide-default-footer": "",
                        "disable-pagination": "",
                        "loading-text": _vm.$t("listLoadingMsg")
                      },
                      on: { "click:row": _vm.addOrEditNewPermission },
                      scopedSlots: _vm._u([
                        {
                          key: "body",
                          fn: function(ref) {
                            var items = ref.items
                            var isSelected = ref.isSelected
                            var select = ref.select
                            return [
                              _c(
                                "tbody",
                                {
                                  ref: "listContainer",
                                  staticClass: "list-container"
                                },
                                _vm._l(items, function(item, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: index,
                                      class: _vm.mobileTableClass,
                                      staticStyle: { cursor: "pointer" },
                                      attrs: { tabindex: "0", role: "row" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.addOrEditNewPermission(
                                            item
                                          )
                                        },
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.addOrEditNewPermission(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm.editMode
                                        ? _c(
                                            "td",
                                            {
                                              class: _vm.mobileRowClass,
                                              on: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm.isMobileMode
                                                ? _c("div", {
                                                    class: _vm.mobileHeaderClass
                                                  })
                                                : _vm._e(),
                                              _c("v-checkbox", {
                                                class: _vm.mobileCellClass,
                                                staticStyle: {
                                                  margin: "0px",
                                                  padding: "0px"
                                                },
                                                attrs: {
                                                  "input-value": isSelected(
                                                    item
                                                  ),
                                                  color: !_vm.$vuetify.theme
                                                    .dark
                                                    ? "#7a7a7a"
                                                    : "#ffffff",
                                                  "hide-details": "",
                                                  "aria-label": item.title
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    select(
                                                      item,
                                                      !isSelected(item)
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("td", { class: _vm.mobileRowClass }, [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.localHeaders[0].text
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { class: _vm.mobileCellClass },
                                          [_vm._v(_vm._s(item.title))]
                                        )
                                      ]),
                                      _c("td", { class: _vm.mobileRowClass }, [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.localHeaders[1].text
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          { class: _vm.mobileCellClass },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "mx-3 hover-button-primary",
                                                attrs: {
                                                  small: "",
                                                  text: "",
                                                  color:
                                                    _vm.$vuetify.breakpoint
                                                      .name === "xs"
                                                      ? "primary"
                                                      : "transparent"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addOrEditNewPermission(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("editLabel"))
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "mx-3 hover-button-error",
                                                attrs: {
                                                  text: "",
                                                  small: "",
                                                  color:
                                                    _vm.$vuetify.breakpoint
                                                      .name === "xs"
                                                      ? "error"
                                                      : "transparent"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deletePermission(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("deleteLabel")
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.selectedRows,
                        callback: function($$v) {
                          _vm.selectedRows = $$v
                        },
                        expression: "selectedRows"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "", width: "100%", "max-width": "800px" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "px-0 py-0" },
                      [
                        _c(
                          "v-container",
                          { staticClass: "px-10" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "12" } }, [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("collaborateTeacherLabel")
                                      )
                                    }
                                  })
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "auto",
                                        label: _vm.$t("teacherKeyLabel"),
                                        outlined: ""
                                      },
                                      model: {
                                        value:
                                          _vm.localSharingOptions.sharedUserKey,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.localSharingOptions,
                                            "sharedUserKey",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "localSharingOptions.sharedUserKey"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "justify-end px-10 pt-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { width: "175px", color: "error" },
                            on: { click: _vm.updateUserKey }
                          },
                          [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._l(_vm.localSharingProfiles, function(profile, i) {
            return _c(
              "v-tab-item",
              { key: i },
              [
                _c("v-form", { ref: "viewProfileForm" + i, refInFor: true }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: "",
                            width: "100%",
                            "max-width": "800px"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "px-0 py-0",
                              staticStyle: { "overflow-y": "auto" },
                              style: {
                                "max-height": _vm.$vuetify.breakpoint.smAndDown
                                  ? "calc(100vh - 300px)"
                                  : "calc(100vh - 180px)"
                              }
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "px-10" },
                                [
                                  _vm.localError.length > 0
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    dense: "",
                                                    border: "left",
                                                    type: "error"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.localError) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { staticClass: "justify-end mr-6" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: i != 0 ? 9 : 10 } },
                                        [
                                          i != 0
                                            ? _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "ml-4 mt-5 mr-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("viewLabel")
                                                        ) + " - "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticStyle: {
                                                      "max-width": "150px"
                                                    },
                                                    attrs: {
                                                      "aria-label": _vm.$t(
                                                        "viewProfileNameLabel"
                                                      ),
                                                      value: profile.name,
                                                      rules:
                                                        _vm.profileNameRules,
                                                      "hide-details": "auto"
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.updateProfileValue(
                                                          i,
                                                          "name",
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c("v-row", [
                                                _c(
                                                  "h3",
                                                  {
                                                    staticClass:
                                                      "ml-4 mt-5 mr-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("viewLabel")
                                                      ) +
                                                        " - " +
                                                        _vm._s(profile.name)
                                                    )
                                                  ]
                                                )
                                              ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "pb-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                                label: _vm.$t("downloadLabel")
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.downloadViewProfile(
                                                    profile,
                                                    i
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-download")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "1" } },
                                        [
                                          _c(
                                            "pb-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                label: _vm.$t("emailToLabel"),
                                                text: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.emailViewProfile(
                                                    profile,
                                                    i
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("fal fa-envelope")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      i != 0
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "1" } },
                                            [
                                              _c(
                                                "pb-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    text: "",
                                                    label: _vm.$t("deleteLabel")
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteProfile(
                                                        profile
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fal fa-trash-alt")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              label:
                                                _vm.$t("viewKeyLabel") + "*",
                                              "aria-required": "true",
                                              rules: _vm.profileKeyRule,
                                              outlined: "",
                                              value: profile.sharedKey
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "sharedKey",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("multi-auto-complete", {
                                            attrs: {
                                              selected:
                                                profile.selectedSharedInfo,
                                              items: _vm.information,
                                              itemText: "label",
                                              itemId: "id",
                                              label: _vm.$t("viewItemsLabel")
                                            },
                                            on: {
                                              "update:selected": function(
                                                $event
                                              ) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "selectedSharedInfo",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.isNotEmpty(_vm.statusList) &&
                                  _vm.statusList.length > 1
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("multi-auto-complete", {
                                                attrs: {
                                                  selected:
                                                    profile.selectedSharedStatus,
                                                  items: _vm.statusList,
                                                  itemText: "statusText",
                                                  itemColor: "color",
                                                  itemId: "lessonStatusId",
                                                  label: _vm.$t(
                                                    "viewStatusesLabel"
                                                  )
                                                },
                                                on: {
                                                  "update:selected": function(
                                                    $event
                                                  ) {
                                                    return _vm.updateProfileValue(
                                                      i,
                                                      "selectedSharedStatus",
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        var attrs = ref.attrs
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-list-item",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var active =
                                                                            ref.active
                                                                          return [
                                                                            _c(
                                                                              "v-list-item-action",
                                                                              [
                                                                                _c(
                                                                                  "v-checkbox",
                                                                                  {
                                                                                    attrs: {
                                                                                      "input-value": active
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-list-item-content",
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  {
                                                                                    domProps: {
                                                                                      textContent: _vm._s(
                                                                                        item.statusText
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            !item.hideIcon
                                                                              ? _c(
                                                                                  "v-list-item-action",
                                                                                  [
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs: {
                                                                                          bottom:
                                                                                            ""
                                                                                        },
                                                                                        scopedSlots: _vm._u(
                                                                                          [
                                                                                            {
                                                                                              key:
                                                                                                "activator",
                                                                                              fn: function(
                                                                                                ref
                                                                                              ) {
                                                                                                var on =
                                                                                                  ref.on
                                                                                                var attrs =
                                                                                                  ref.attrs
                                                                                                return [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    _vm._g(
                                                                                                      _vm._b(
                                                                                                        {},
                                                                                                        "span",
                                                                                                        attrs,
                                                                                                        false
                                                                                                      ),
                                                                                                      on
                                                                                                    ),
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-checkbox",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            "on-icon":
                                                                                                              "fal fa-eye",
                                                                                                            "off-icon":
                                                                                                              "fal fa-eye-slash",
                                                                                                            color:
                                                                                                              "orange"
                                                                                                          },
                                                                                                          on: {
                                                                                                            click: function(
                                                                                                              $event
                                                                                                            ) {
                                                                                                              $event.preventDefault()
                                                                                                              $event.stopPropagation()
                                                                                                            }
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              profile
                                                                                                                .showLessonStatus[
                                                                                                                item
                                                                                                                  .lessonStatusId
                                                                                                              ],
                                                                                                            callback: function(
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                profile.showLessonStatus,
                                                                                                                item.lessonStatusId,
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "profile.showLessonStatus[item.lessonStatusId]"
                                                                                                          }
                                                                                                        }
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                ]
                                                                                              }
                                                                                            }
                                                                                          ],
                                                                                          null,
                                                                                          true
                                                                                        )
                                                                                      },
                                                                                      [
                                                                                        profile
                                                                                          .showLessonStatus[
                                                                                          item
                                                                                            .lessonStatusId
                                                                                        ]
                                                                                          ? _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "showStatusLabel"
                                                                                                    )
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "hideStatusLabel"
                                                                                                    )
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                "v-list-item",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("multi-auto-complete", {
                                            attrs: {
                                              selected:
                                                profile.selectedSharedClasses,
                                              items: _vm.localClasses,
                                              itemText: "formattedName",
                                              itemId: "cId",
                                              itemColor: "cC",
                                              label: _vm.$t("viewClassesLabel")
                                            },
                                            on: {
                                              "update:selected": function(
                                                $event
                                              ) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "selectedSharedClasses",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "hide-details": "auto",
                                              items: _vm.previousRange,
                                              "item-text": "name",
                                              "item-value": "value",
                                              outlined: "",
                                              label: _vm.$t("viewRangeLabel"),
                                              value: profile.sharedWeeksStart
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "sharedWeeksStart",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "justify-center",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-4 text-center" },
                                            [_vm._v(_vm._s(_vm.$t("toLabel")))]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "5" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "hide-details": "auto",
                                              items: _vm.futureRange,
                                              "item-text": "name",
                                              "item-value": "value",
                                              outlined: "",
                                              label: _vm.$t("viewRangeLabel"),
                                              value: profile.sharedWeeks
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "sharedWeeks",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "hide-details": "auto",
                                              items: _vm.defaultView,
                                              "item-text": "name",
                                              "item-value": "value",
                                              outlined: "",
                                              label: _vm.$t(
                                                "defaultLayoutLabel"
                                              ),
                                              value: profile.defaultSharingView
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "defaultSharingView",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-switch", {
                                            staticClass: "pa-0 ma-0",
                                            attrs: {
                                              inset: "",
                                              label: _vm.$t(
                                                "includeViewNotesLabel"
                                              ),
                                              "hide-details": "auto",
                                              "input-value":
                                                profile.includeViewNotes
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateProfileValue(
                                                  i,
                                                  "includeViewNotes",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          profile.includeViewNotes
                                            ? _c("pb-text-editor", {
                                                attrs: {
                                                  config: { height: "300px" },
                                                  value: profile.sharedNotes
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.updateProfileValue(
                                                      i,
                                                      "sharedNotes",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-end px-10 pt-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { width: "175px", color: "error" },
                                  on: { click: _vm.updateSharingOptions }
                                },
                                [_vm._v(_vm._s(_vm.$t("saveLabel")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "pb-base-modal",
        {
          attrs: { maxWidth: 500, expandable: false, withActions: false },
          on: { apply: _vm.createNewViewProfile },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [_vm._v(_vm._s(_vm.$t("addViewProfileLabel")))]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "pb-7" },
            [
              _c(
                "v-form",
                { ref: "newViewProfileForm" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("viewProfileNameLabel") + "*",
                              rules: _vm.profileNameRules,
                              required: "",
                              outlined: "",
                              "hide-details": "auto",
                              autofocus: "",
                              "aria-required": "true"
                            },
                            model: {
                              value: _vm.viewProfileName,
                              callback: function($$v) {
                                _vm.viewProfileName = $$v
                              },
                              expression: "viewProfileName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }