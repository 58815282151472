















































































































































































































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ld from 'lodash';
import Confirm from './../../components/core/Confirm.vue';
import MultiAutoComplete from './../../components/core/MultiAutoComplete.vue';
import { profileFormat, sections } from '@/constants';
import { VForm } from 'vuetify/lib';

const settings = namespace('settings');
const classes = namespace('classes');
const email = namespace('email');
const permissions = namespace('permissions');

@Component({
  components: {
    MultiAutoComplete
  }
})
export default class SharingOptions extends Vue {
  currentTab = 0;
  localSharingOptions: any = '';
  selectAllInformation = true;
  localSharingProfiles:any[] = [];
  information: any[] = [];
  status: any[] = [];
  previousRange = [
    { name: this.$t('currentDayLabel'), value: 999 },
    { name: this.$t('currentWeekLabel'), value: 0 },
    { name: this.$t('priorWeek1'), value: -1 },
    { name: this.$t('priorWeek2'), value: -2 },
    { name: this.$t('priorWeek3'), value: -3 },
    { name: this.$t('priorWeek4'), value: -4 },
    { name: this.$t('priorWeek5'), value: -5 },
    { name: this.$t('priorWeekAll'), value: -100 }
  ];

  futureRange = [
    { name: this.$t('currentDayLabel'), value: 999 },
    { name: this.$t('currentWeekLabel'), value: 0 },
    { name: this.$t('futureDay1'), value: 1000 },
    { name: this.$t('futureWeek1'), value: 1 },
    { name: this.$t('futureWeek2'), value: 2 },
    { name: this.$t('futureWeek3'), value: 3 },
    { name: this.$t('futureWeek4'), value: 4 },
    { name: this.$t('futureWeek5'), value: 5 },
    { name: this.$t('futureWeekAll'), value: 100 }
  ];

  defaultView = [{ name: this.$t('dayLabel'), value: 'D' }, { name: this.$t('weekLabel'), value: 'W' }, { name: this.$t('monthLabel'), value: 'M' }, { name: this.$t('listLabel'), value: 'L' }];
  selectedRows:any = [];
  editMode = false;
  localSearch = '';
  localError = '';
  dialog = false;
  viewProfileName: any = '';
  isTableLoading = false;
  isSubPageOpen = false;
  statusList: Array<any> = [];
  classList:Array<any> = [];
  localSchoolYearNamesMap = new Map();

  localHeaders = [
    {
      text: this.$t('permissionTitleLabel'),
      value: 'title',
      sortable: true
    },
    { width: 200, sortable: false, value: 'action' }
  ];

  $refs!: {
    deleteViewProfileConfirm: Confirm,
    newViewProfileForm: InstanceType<typeof VForm>,
  }

  adminSharingOptions = {
    allowCreateLessonBanks: true,
    allowViewLessonBanks: true
  }

  @email.Action
  sendEmail!: (param?: any) => Promise<any>;

  @settings.State
  adminSchoolYears!: Array<any>;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getStatuses')
  viewStatusItems!: any;

  @settings.Getter
  getSchoolSettings!: any;

  @settings.Getter
  getLessonSections!: any;

  @settings.Getter('getSchoolYears')
  teacherSchoolYears!: Array<any>;

  @settings.Mutation
  setSharedUserKey!: (key: string) => void

  @settings.Mutation
  setSharingProfiles!: (params: any) => void

  @settings.Mutation
  deleteSharingProfile!: (index: number) => void

  @settings.Getter('getSchoolId')
  schoolId!: any;

  @settings.Mutation
  setAllowCreateLessonBanks!: (value: boolean) => void;

  @settings.Mutation
  setAllowViewLessonBanks!: (value: boolean) => void;

  @settings.State
  currentYearId!: any;

  @settings.Action('save')
  saveSharingOptions!: (params?: any) => Promise<any>;

  @classes.State
  classes!: Array<any>;

  @permissions.Action
  getPermissions!: (params?: any) => Promise<any>;

  @permissions.Action
  saveAdminPermissions!: (params?: any) => Promise<any>;

  @permissions.Getter('getPermissionsData')
  lessonPermissions!: any[];

  @permissions.Getter('getIsFetchingData')
  isFetchingData!: any[];

  @permissions.Mutation
  setFetchingData!: (v: boolean) => void

  @settings.Getter
  getLessonSectionLabel!: (s: string) => string;

  @settings.Getter
  getOtherLessonLayoutLabels!: (s: string) => Array<string>;

  @settings.Getter
  isLessonSectionAndEnabled!: (s: string, checkLayouts?: boolean) => boolean;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get isMobileMode() {
    return this.$vuetify.breakpoint.xs;
  }

  get mobileTableClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-table-row' : '';
  }

  get mobileRowClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-row' : '';
  }

  get mobileHeaderClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-row__header' : '';
  }

  get mobileCellClass() {
    return (this.isMobileMode) ? 'v-data-table__mobile-row__cell' : '';
  }

  get currentTeacherId(): number {
    if (this.userMode === 'T') {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  get profileNameRules() {
    return [
      (v: string) => !!v || this.$t('requiredMsg2'),
      (v: string) => profileFormat.test(v) || this.$t('invalidProfileFormatMsg')
    ]
  }

  get profileKeyRule() {
    return [
      (v: string) => profileFormat.test(v) || this.$t('invalidProfileFormatMsg')
    ]
  }

  get showTabs() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.$currentUser.isTeacher;
    }
    return true;
  }

  get localPermissions() {
    return this.lessonPermissions;
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get localClasses() {
    const enhancedClassItems: any = [];
    this.classes.forEach((ci: any) => {
      const classItem = ld.cloneDeep(ci);
      classItem.formattedName = ci.cN + ' (' + this.localSchoolYearNamesMap.get(ci.cYId) + ')';
      enhancedClassItems.push(classItem);
    });
    return (enhancedClassItems || []).filter((c:any) => c.collaborateType !== 4);
  }

  get schoolYears() {
    return (this.userMode === 'A' ? this.adminSchoolYears : this.teacherSchoolYears) || [];
  }

  updateProfileValue(i: number, attributeKey: string, attributeValue: any) {
    const clone = ld.cloneDeep(this.localSharingProfiles);
    ld.set(clone, `[${i}].${attributeKey}`, attributeValue);
    this.localSharingProfiles = clone;
  }

  @Watch('isFetchingData')
  onTableDataChanged(v:any) {
    this.isTableLoading = v;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  addOrEditNewPermission(itemData:any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'sharingoptions',
      modal: this.$currentUser.defaultEditorMode === 'modal',
      width: 600,
      input: {
        data: itemData,
        doWhat: CommonUtils.isEmpty(Object.keys(itemData)) ? 'add' : 'edit'
      }
    });
    this.isSubPageOpen = true;
  }

  deletePermission(itemData?:any) {
    return this.$refs.deleteViewProfileConfirm.confirm({
      title: this.$t('deletePermissionLabel'),
      text: (itemData) ? this.$t('deletePermissionText') : this.$t('deletePermissionsText'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.setFetchingData(true);
        CommonUtils.showLoading();
        let payload:any = [];
        if (itemData) {
          payload = this.localPermissions.filter(p => p.id !== itemData.id);
        } else {
          const selectedRowIds = this.selectedRows.map((r: any) => { return r.id });
          payload = this.localPermissions.filter((p:any) => !selectedRowIds.includes(p.id));
          this.selectedRows = [];
        }
        if (this.userMode === 'A') {
          this.savePermission({ permissions: payload, adminOptions: this.adminSharingOptions });
        } else {
          this.savePermission(payload);
        }
      }
    });
  }

  savePermission(payload: any) {
    if (this.$currentUser.isAdmin) {
      return this.saveAdminPermissions({ permissions: JSON.stringify(payload.permissions), sharingOptions: payload.adminOptions }).then(() => {
        return this.getPermissions({
          teacherId: this.currentTeacherId,
          schoolId: this.schoolId,
          userMode: this.userMode
        }).then(() => {
          CommonUtils.hideLoading();
        });
      });
    } else {
      return this.saveSharingOptions({
        permissions: JSON.stringify(payload)
      }).then(() => {
        return this.getPermissions({
          teacherId: this.currentTeacherId,
          schoolId: this.schoolId,
          userMode: this.userMode
        }).then(() => {
          CommonUtils.hideLoading();
        });
      });
    }
  }

  openNewViewProfile() {
    const form: any = this.$refs.newViewProfileForm;
    if (form) {
      form.resetValidation();
    }
    this.dialog = true;
  }

  createNewViewProfile() {
    const form: any = this.$refs.newViewProfileForm;
    if (this.localSharingProfiles && form.validate()) {
      const newprofile = ld.cloneDeep(this.localSharingProfiles[0]);
      newprofile.name = this.viewProfileName;
      newprofile.sharedKey = '';
      newprofile.selectedSharedInfo = this.information.map((i: any) => { return i.id });
      newprofile.selectedSharedStatus = this.statusList.map((s: any) => { return s.lessonStatusId });
      newprofile.selectedSharedClasses = this.localClasses.map((c: any) => { return c.cId })
      newprofile.sharedWeeksStart = 0;
      newprofile.sharedWeeks = 0;
      newprofile.defaultSharingView = 'W';
      newprofile.includeViewNotes = false;
      for (const key in newprofile.showLessonStatus) {
        newprofile.showLessonStatus[key] = false;
      }
      newprofile.sharedNotes = '';
      this.localSharingProfiles.push(newprofile);
      this.viewProfileName = '';
      this.dialog = false;
    }
  }

  initialize() {
    CommonUtils.showLoading();
    if (this.$currentUser.isAdmin) {
      this.adminSharingOptions.allowCreateLessonBanks = this.getSchoolSettings.primarySchool.allowCreateLessonBanks
      this.adminSharingOptions.allowViewLessonBanks = this.getSchoolSettings.primarySchool.allowViewLessonBanks
    }
    this.getPermissions({
      teacherId: this.currentTeacherId,
      schoolId: this.schoolId,
      userMode: this.userMode
    }).then(() => {
      this.localSharingOptions = ld.cloneDeep(this.userInfo.sharingOptionsSettings);
      this.localSharingProfiles = ld.cloneDeep(this.userInfo.otherSettings.sharingProfiles);
      this.information = this.getSharingSections();
      this.loadViewProfiles(this.localSharingProfiles);
      this.prepareStatusItems(this.viewStatusItems);
      CommonUtils.hideLoading();
    });
    this.schoolYears.forEach((sy: any) => {
      this.localSchoolYearNamesMap.set(sy.yearId, sy.yearName);
    });
  }

  tabChange(newTab: any) {
    this.currentTab = newTab;
  }

  @Watch('currentTab')
  onCurrentTabChange() {
    this.resetViewProfileForms();
  }

  getSharingSections() {
    const dynamicSections = [
      { label: this.$t('eventsLabel'), displayOrder: 8, id: 'events', enabledValue: 'Y' },
      { label: this.$t('googleEventsLabel'), displayOrder: 9, id: 'googleEvents', enabledValue: 'Y' },
      { label: this.$t('assessmentsLabel'), displayOrder: 15, id: 'assessments', enabledValue: 'Y' },
      { label: this.$t('assignmentsLabel'), displayOrder: 16, id: 'assignments', enabledValue: 'Y' },
      { label: this.$t('gradesPermissionLabel'), displayOrder: 17, id: 'grades', enabledValue: 'Y' }
    ];
    const userSections: any = sections.filter((s: any) => s !== 'attachments').map((s: string) => {
      const section = this.getLessonSections[s];
      const otherTexts = this.getOtherLessonLayoutLabels(s) || [];
      return {
        id: this.getSectionId(s),
        displayOrder: section[s + 'DisplayOrder'],
        enabledValue: CommonUtils.booleanToString(this.isLessonSectionAndEnabled(s, true)),
        label: this.getLessonSectionLabel(s),
        toolTip: otherTexts.join(', ')
      }
    })
    return dynamicSections.concat(userSections).sort((a, b) => a.displayOrder - b.displayOrder).filter((s: any) => s.enabledValue === 'Y');
  }

  getSectionId(s: string) {
    switch (s) {
      case 'tab2': return 'homework';
      case 'tab3': return 'notes';
      case 'sharedStandards': return 'standards';
      case 'myStandards': return 'myList';
      case 'schoolStandards': return 'schoolList';
      default: return s;
    }
  }

  loadViewProfiles(sharingProfiles: any) {
    if (sharingProfiles) {
      for (const index in sharingProfiles) {
        // shared info
        const sharedInfo: any = [];
        const sharingProfile = sharingProfiles[index];
        for (const key in sharingProfile.sharedInformation) {
          if (sharingProfile.sharedInformation[key]) {
            sharedInfo.push(key)
          }
        }
        sharingProfile.selectedSharedInfo = sharedInfo;

        // shared classes
        const sharedClasses: any = [];
        for (const key in sharingProfile.sharedClasses[this.currentYearId]) {
          if (sharingProfile.sharedClasses[this.currentYearId][key]) {
            sharedClasses.push(Number(key))
          }
        }
        sharingProfile.selectedSharedClasses = sharedClasses;

        if ((sharingProfile.name === 'Students' || sharingProfile.name === 'Substitutes') && CommonUtils.isEmpty(Object.keys(sharingProfile.sharedClasses)) && !sharingProfile.sharedKey) {
          sharingProfile.selectedSharedClasses = this.localClasses.map((c: any) => { return c.cId });
        }

        // shared statuses
        const statusInfo: any = [];
        const statusShown: any = [];
        if (sharingProfile.shareNoStatus && !statusInfo.includes(0)) {
          statusInfo.push(0);
        }
        for (const key in sharingProfile.sharedLessonStatus) {
          if (sharingProfile.sharedLessonStatus[key]) {
            statusInfo.push(Number(key))
          }
          if (sharingProfile.showLessonStatus[key]) {
            statusShown.push(Number(key))
          }
        }
        sharingProfile.selectedSharedStatus = statusInfo;
        sharingProfile.includeViewNotes = sharingProfile.sharedNotes.length > 0;
      }
    }
  }

  prepareStatusItems(viewStatuses: any) {
    this.statusList = [{ statusText: this.$t('noStatusLabel'), lessonStatusId: 0, visible: false, hideIcon: true }].concat(viewStatuses);
  }

  updateAdminSharingOptions() {
    CommonUtils.showLoading();
    this.savePermission({ permissions: this.localPermissions, adminOptions: this.adminSharingOptions });
  }

  async save() {
    return this.saveSharingOptions().then(resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg25') as string);
      }
      return Promise.resolve(resp);
    });
  }

  updateUserKey() {
    CommonUtils.showLoading();
    this.setSharedUserKey(this.localSharingOptions.sharedUserKey);
    this.save().then(() => {
      CommonUtils.hideLoading();
      return Promise.resolve(false);
    })
  }

  async updateSharingOptions() {
    if (!this.validateViewProfile()) {
      return;
    }
    CommonUtils.showLoading();
    for (const i in this.localSharingProfiles) {
      const sharingProfile = this.localSharingProfiles[i];
      const selectedSharedInfo = sharingProfile.selectedSharedInfo || [];
      this.information.forEach(i => {
        const key = i.id;
        sharingProfile.sharedInformation[key] = selectedSharedInfo.includes(key);
      })
      delete sharingProfile.selectedSharedInfo;

      let currentYearSharedClasses = sharingProfile.sharedClasses[this.currentYearId];
      if (!currentYearSharedClasses) currentYearSharedClasses = sharingProfile.sharedClasses[this.currentYearId] = {};
      const selectedSharedClasses = sharingProfile.selectedSharedClasses || [];
      this.classes.forEach(c => {
        currentYearSharedClasses[c.cId] = selectedSharedClasses.includes(+c.cId)
      })
      delete sharingProfile.selectedSharedClasses;

      const selectedSharedStatus = sharingProfile.selectedSharedStatus || [];
      this.statusList.forEach(s => {
        if (s.lessonStatusId !== 0) {
          sharingProfile.sharedLessonStatus[s.lessonStatusId] = selectedSharedStatus.includes(s.lessonStatusId);
        } else {
          delete sharingProfile.sharedLessonStatus[0];
        }
      })
      sharingProfile.shareNoStatus = selectedSharedStatus.includes(0);
      delete sharingProfile.selectedSharedStatus;

      if (!sharingProfile.includeViewNotes) {
        sharingProfile.sharedNotes = '';
      }
    }

    this.setSharingProfiles(this.localSharingProfiles);
    return this.save().finally(() => {
      this.loadViewProfiles(this.localSharingProfiles);
      CommonUtils.hideLoading();
    })
  }

  validateViewProfile(index?: any) {
    if (this.localSharingProfiles[index] && CommonUtils.hasNoText(this.localSharingProfiles[index].sharedKey)) {
      this.localError = this.$t('viewKeyError1') as string;
      return false;
    }

    const profilesWithKey = this.localSharingProfiles.filter((profile) => { return CommonUtils.hasText(profile.sharedKey) })
    const profileKeys = new Set(profilesWithKey.map((profile) => { return profile.sharedKey }));
    if (profileKeys.size !== profilesWithKey.length) {
      this.localError = this.$t('viewKeyError2') as string;
      return false;
    }
    this.localError = '';
    if (index) {
      const r: any = this.$refs;
      return (r['viewProfileForm' + index]) ? r['viewProfileForm' + index][0].validate() : true;
    } else {
      return this.validateViewProfileForms();
    }
  }

  resetViewProfileForms() {
    for (const key in this.$refs) {
      const form = (this.$refs as any)[key][0];
      if (key.startsWith('viewProfileForm')) {
        try {
          form.resetValidation();
        } catch (e) {
        }
      }
    }
  }

  validateViewProfileForms() {
    for (const key in this.$refs) {
      const form = (this.$refs as any)[key][0];
      if (key.startsWith('viewProfileForm')) {
        try {
          if (!form.validate()) {
            return false;
          }
        } catch (e) {
        }
      }
    }
    return true;
  }

  downloadViewProfile(viewProfile: any, index: any) {
    if (!this.validateViewProfile(index)) {
      return;
    }
    this.updateSharingOptions().then(() => {
      let text = this.$t('viewProfileYearHeading', { name: viewProfile.name, break: '\r\n' });
      text = text + CommonUtils.getAppBaseUrl() + '?t=' + this.userInfo.userId + '&k=' + viewProfile.sharedKey + '&v=' + viewProfile.defaultSharingView + '&y=' + this.currentYearId + '\r\n\r\n\r\n';
      text = text + this.$t('viewProfileClassHeading', { name: viewProfile.name, break: '\r\n' });
      this.localClasses.filter((c: any) => viewProfile.selectedSharedClasses.includes(c.cId)).forEach((item: any) => {
        text = text + item.cN + '\r\n' + CommonUtils.getAppBaseUrl() + '?t=' + this.userInfo.userId + '&k=' + viewProfile.sharedKey + '&v=' + viewProfile.defaultSharingView + '&c=' + item.cId + '&y=' + this.currentYearId + '\r\n\r\n';
      });
      const blob = new Blob([text]);
      const element = document.createElement('a');
      element.href = window.URL.createObjectURL(blob);
      element.download = 'viewLinks' + new Date().getTime() + '.txt';
      element.click();
    });
  }

  emailViewProfile(viewProfile: any, index: any) {
    if (!this.validateViewProfile(index)) {
      return;
    }
    this.updateSharingOptions().then(() => {
      let text = this.$t('viewProfileYearHeading', { name: viewProfile.name, break: '<br>' });
      text = text + CommonUtils.getAppBaseUrl() + '?t=' + this.userInfo.userId + '&k=' + viewProfile.sharedKey + '&v=' + viewProfile.defaultSharingView + '&y=' + this.currentYearId + '<br><br><br>';
      text = text + this.$t('viewProfileClassHeading', { name: viewProfile.name, break: '<br>' });
      this.localClasses.filter((c: any) => viewProfile.selectedSharedClasses.includes(c.cId)).forEach((item: any) => {
        text = text + item.cN + '<br>' + CommonUtils.getAppBaseUrl() + '?t=' + this.userInfo.userId + '&k=' + viewProfile.sharedKey + '&v=' + viewProfile.defaultSharingView + '&c=' + item.cId + '&y=' + this.currentYearId + '<br><br>';
      });
      this.sendEmail({ message: text, subject: this.$t('viewProfileEmailHeading') })
    });
  }

  deleteProfile(item: any) {
    if (this.localSharingProfiles) {
      this.$refs.deleteViewProfileConfirm.confirm({
        title: this.$t('viewLabel') + ' - ' + item.name,
        text: this.$t('deleteViewLabel'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          const index = this.localSharingProfiles.indexOf(item);
          if (index > 0) {
            this.localSharingProfiles.splice(index, 1);
            this.deleteSharingProfile(index);
            this.save();
          }
        }
      });
    }
  }

  doInit(reload: boolean) {
    this.isSubPageOpen = false;
    if (reload) {
      this.initialize();
    }
  }

  onUserModeChanged() {
    this.doInit(true);
  }

  created() {
    this.$nextTick(() => {
      this.initialize();
      this.$eventBus.$on('closePermissionEditor', (reload: boolean) => {
        this.doInit(reload);
      });

      this.$eventBus.$on('userModeChanged', this.onUserModeChanged);
    });
  }

  destroyed() {
    this.$eventBus.$off('closePermissionEditor');
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
  }
}
